<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0 mb-2">Sent JOB Surveys</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchEmails"
            name="search"
            placeholder="Search by survey job title, email or lastname..."
            data-kt-search-element="input"
          />
          <p class="px-5 mb-5">{{ filterEmails.length }} returned</p>
        </div>
        <!--end::Search-->
        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin::Word Container-->
          <table class="table table-striped gy-3 gs-3">
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="min-w-150px">Survey Job Title</th>
                <th class="min-w-150px">Recipient</th>
                <th class="min-w-140px">Company</th>
                <th class="min-w-120px">Status</th>
                <th class="min-w-100px">Sent</th>
                <th class="min-w-100px text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in filterEmails"
                :key="item.respondentId"
                :respondentId="'respondent_' + index"
              >
                <td>
                  <div class="d-flex flex-column">
                    <p class="text-dark fw-bolder fs-6 mb-0">
                      {{ item.surveyJobTitle }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p class="text-dark fw-bolder fs-6 mb-0">
                      {{ item.recipientLastName }}, {{ item.recipientFirstName
                      }}<br /><span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.jobTitle }}</span
                      ><span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.recipientEmail }}</span
                      >
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p
                      v-if="item.clientName !== 'na'"
                      class="text-dark fw-bolder d-block fs-6"
                    >
                      {{ item.clientName }}
                    </p>
                    <p v-else class="text-dark fw-bolder d-block fs-6">
                      {{ item.sentByName }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-stack mb-2">
                      <span class="text-muted me-2 fs-7 fw-bold">
                        <span
                          v-if="item.completed"
                          class="badge badge-light-success"
                          >Completed</span
                        >
                        <span v-else class="badge badge-light-danger"
                          >Waiting</span
                        >
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <p class="text-dark fw-bolder d-block fs-6">
                      {{ d(item.sentDateUtc) }}
                    </p>
                  </div>
                </td>
                <td class="text-end">
                  <!--begin::Delete-->
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                    @click="deleteEmail(item.id)"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="../media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                  <!--end::Delete-->
                </td>
              </tr>
            </tbody>
          </table>
          <!--begin::Word Container-->
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { EmailQueListRequestDto } from "../../core/types/EmailTypes";
import { DeleteEmailQueRequestDto } from "../../core/types/EmailTypes";

export default defineComponent({
  name: "sent-email-job-surveys",
  props: ["surveytype"],
  components: {
    TableSkeleton,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);
    const { d } = useI18n();
    const emailQueListRequestDto = ref<EmailQueListRequestDto>({
      userRole: "",
      userAspNetUserId: "",
      typeOfSurvey: props.surveytype,
      fromRoute: "sent-survey",
    });

    const deleteEmailQueRequestDto = ref<DeleteEmailQueRequestDto>({
      id: 0,
      typeOfSurveyEmail: props.surveytype,
    });

    const ques = computed(() => {
      return store.getters.getAllJobQuedEmails;
    });
    const searchEmails = ref("");

    onMounted(() => {
      setCurrentPageTitle("Emails");
      setCurrentPageBreadcrumbs("Sent Surveys", ["JOB Surveys"]);

      emailQueListRequestDto.value.userRole = currentUser.role;
      emailQueListRequestDto.value.userAspNetUserId = currentUser.id;
      store
        .dispatch(Actions.GET_ALL_EMAIL_QUES, emailQueListRequestDto.value)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving all your sent emails.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    });

    const deleteEmail = (id) => {
      Swal.fire({
        title: "Are you sure you want to remove this email?",
        showCancelButton: true,
        confirmButtonText: "Yes. Remove",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          deleteEmailQueRequestDto.value.id = id;

          store
            .dispatch(Actions.DELETE_EMAIL_QUE, deleteEmailQueRequestDto.value)
            .then(() => {
              Swal.fire("The email has been removed!", "", "success");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem removing the email.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const filterEmails = computed(() => {
      return ques.value.filter((emailQue) => {
        return (
          emailQue.recipientEmail
            .toLowerCase()
            .indexOf(searchEmails.value.toLowerCase()) != -1 ||
          emailQue.recipientLastName
            .toLowerCase()
            .indexOf(searchEmails.value.toLowerCase()) != -1 ||
          emailQue.surveyJobTitle
            .toLowerCase()
            .indexOf(searchEmails.value.toLowerCase()) != -1
        );
      });
    });

    watch(ques, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      isDataReady,
      ques,
      d,
      deleteEmail,
      deleteEmailQueRequestDto,
      emailQueListRequestDto,
      searchEmails,
      filterEmails,
    };
  },
});
</script>
